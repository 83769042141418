import { createAlchemyWeb3 } from "@alch/alchemy-web3";
// import { AlchemyProvider } from "ethers";
import { parseUnits, formatUnits, isAddress } from "ethers";
import contractABI from "../assets/abis/v4/NewAuction.json";
import tetherTokenABI from "../assets/abis/v4/CUSDT.json";
import bidCoinABI from "../assets/abis/v4/BidCoin.json";
const tetherTokenAddress = process.env.REACT_APP_CUSDT_ADDRESS;
const bidCoinAddress = process.env.REACT_APP_BIDCOIN_ADDRESS;

const alchemyKey =
  "wss://arb-mainnet.g.alchemy.com/v2/z3GTWUvrqDHlJnoPCAfcAihbeVNJFkzg";
const web3 = createAlchemyWeb3(alchemyKey);

export const tetherTokenContract = new web3.eth.Contract(
  tetherTokenABI.abi,
  tetherTokenAddress
);

export const bidCoinContract = new web3.eth.Contract(
  bidCoinABI.abi,
  bidCoinAddress
);

// Create a function to initialize the auction contract
export const initializeAuctionContract = (contractAddress) => {
  return new web3.eth.Contract(contractABI.abi, contractAddress);
};

// Update loadAuctionData to accept contractAddress
export const loadAuctionData = async ({ address }) => {
  const auctionContract = initializeAuctionContract(address);
  try {
    const totalProfit = await auctionContract.methods.totalProfit().call();
    const assetDescription = await auctionContract.methods
      .assetDescription()
      .call();
    const assetLocation = await auctionContract.methods.assetLocation().call();
    const assetType = await auctionContract.methods.assetType().call();
    const auctionEndTime = await auctionContract.methods
      .auctionEndTime()
      .call();
    const auctionEnded = await auctionContract.methods.auctionEnded().call();
    const currentBidPrice = await auctionContract.methods
      .currentBidPrice()
      .call();
    const discountFee = await auctionContract.methods.discountFee().call();
    const fomoPhaseActive = await auctionContract.methods
      .fomoPhaseActive()
      .call();
    const winningBidder = await auctionContract.methods.winningBidder().call();

    const safeFormatUnits = (value, decimals) => {
      try {
        return Number(formatUnits(value || "0", decimals)).toString();
      } catch (error) {
        console.error("Error formatting units:", error);
        return "0";
      }
    };

    let auctionData = {
      totalProfit,
      assetDescription,
      assetLocation,
      assetType,
      auctionEndTime,
      auctionEnded,
      currentBidPrice,
      discountFee,
      fomoPhaseActive,
      winningBidder,
      // usdtBalance: safeFormatUnits(usdtBalance, 18),
      // bidBalance: safeFormatUnits(bidBalance, 18),
      address: address, // Use the passed address
    };
    const addressArray = await window.ethereum.request({
      method: "eth_accounts",
    });

    const userAddress = addressArray.length > 0 ? addressArray[0] : "0x0";
    if (isAddress(userAddress) == true) {
      const usdtBalance = await tetherTokenContract.methods
        .balanceOf(userAddress)
        .call();
      const bidBalance = await bidCoinContract.methods
        .balanceOf(userAddress)
        .call();

      auctionData.usdtBalance = safeFormatUnits(usdtBalance, 18);
      auctionData.bidBalance = safeFormatUnits(bidBalance, 18);
    }

    return auctionData;
  } catch (error) {
    console.error("Error loading auction data:", error);
    return null;
  }
};

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "👆🏽 Write a message in the text-field above.",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Input Stake / Unstake Amount.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const placeBidHandler = async (userAddress, contractAddress) => {
  const userWallet = userAddress;
  if (!window.ethereum || userAddress === null) {
    return {
      status: "💡 Connect your wallet provider to continue.",
    };
  }

  try {
    const auctionContract = initializeAuctionContract(contractAddress);
    console.log("🚀 ~ placeBidHandler ~ auctionContract:", auctionContract);

    // Get the current discount fee
    const discountFee = await auctionContract.methods.discountFee().call();
    const usdtAmountInWei = parseUnits(discountFee.toString(), 18);
    console.log("🚀 ~ placeBidHandler ~ usdtAmountInWei:", usdtAmountInWei);

    //?Test Mint
    // const testMint = await tetherTokenContract.methods
    //   .transfer(userWallet, usdtAmountInWei)
    //   .call();
    //?Test Mint
    // Check allowance
    const currentAllowance = await tetherTokenContract.methods
      .allowance(userWallet, contractAddress)
      .call();

    if (BigInt(currentAllowance) < BigInt(usdtAmountInWei)) {
      // Approve spending of USDT only if the current allowance is insufficient
      const approveTransactionParameters = {
        to: tetherTokenAddress,
        from: userWallet,
        data: tetherTokenContract.methods
          .approve(contractAddress, usdtAmountInWei)
          .encodeABI(),
      };
      console.log(
        "🚀 ~ placeBidHandler ~ approveTransactionParameters:",
        approveTransactionParameters
      );

      const approveTxHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [approveTransactionParameters],
      });
      console.log("🚀 ~ placeBidHandler ~ approveTxHash:", approveTxHash);

      // Wait for the approval transaction to be mined
      await new Promise((resolve) => {
        const checkTx = async () => {
          const receipt = await web3.eth.getTransactionReceipt(approveTxHash);
          if (receipt) {
            resolve();
          } else {
            setTimeout(checkTx, 1000); // Check again after 1 second
          }
        };
        checkTx();
      });
    } else {
      console.log("Sufficient allowance already exists. Skipping approval.");
    }

    // Place the bid
    const bidTransactionParameters = {
      to: contractAddress,
      from: userWallet,
      data: auctionContract.methods.placeBid().encodeABI(),
    };

    const bidTxHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [bidTransactionParameters],
    });
    console.log("🚀 ~ placeBidHandler ~ bidTxHash:", bidTxHash);

    const totalProfit = await auctionContract.methods.totalProfit().call();
    const assetDescription = await auctionContract.methods
      .assetDescription()
      .call();
    const assetLocation = await auctionContract.methods.assetLocation().call();
    const assetType = await auctionContract.methods.assetType().call();
    const auctionEndTime = await auctionContract.methods
      .auctionEndTime()
      .call();
    const auctionEnded = await auctionContract.methods.auctionEnded().call();
    const currentBidPrice = await auctionContract.methods
      .currentBidPrice()
      .call();
    const fomoPhaseActive = await auctionContract.methods
      .fomoPhaseActive()
      .call();
    const winningBidder = await auctionContract.methods.winningBidder().call();

    const addressArray = await window.ethereum.request({
      method: "eth_accounts",
    });

    const userAddress = addressArray.length > 0 ? addressArray[0] : "0x0";
    const usdtBalance = await tetherTokenContract.methods
      .balanceOf(userWallet)
      .call();
    const bidBalance = await bidCoinContract.methods
      .balanceOf(userWallet)
      .call();

    const safeFormatUnits = (value, decimals) => {
      try {
        return Number(formatUnits(value || "0", decimals)).toString();
      } catch (error) {
        console.error("Error formatting units:", error);
        return "0";
      }
    };

    let auctionData = {
      totalProfit,
      assetDescription,
      assetLocation,
      assetType,
      auctionEndTime,
      auctionEnded,
      currentBidPrice,
      discountFee,
      fomoPhaseActive,
      winningBidder,
      usdtBalance: safeFormatUnits(usdtBalance, 18),
      bidBalance: safeFormatUnits(bidBalance, 18),
      address: contractAddress,
    };

    // return auctionData;
    return {
      status: true,
      data: auctionData,
    };
  } catch (error) {
    console.error("Error placing bid: " + error);
    return {
      status: "😥 " + error.message,
    };
  }
};

export const burnBidHandler = async (userAddress, contractAddress) => {
  const userWallet = userAddress;
  if (!window.ethereum || userAddress === null) {
    return {
      status: "💡 Connect your wallet provider to continue.",
    };
  }

  try {
    const auctionContract = initializeAuctionContract(contractAddress);
    console.log("🚀 ~ placeBidHandler ~ auctionContract:", auctionContract);

    // Get the current discount fee
    const discountFee = await auctionContract.methods.discountFee().call();
    console.log("🚀 ~ burnBidHandler ~ discountFee:", discountFee);
    const usdtPerBidCoin = await auctionContract.methods
      .USDT_PER_BIDCOIN()
      .call();
    console.log("🚀 ~ burnBidHandler ~ usdtPerBidCoin:", usdtPerBidCoin);
    const bidAmount = discountFee / usdtPerBidCoin;
    console.log("🚀 ~ burnBidHandler ~ bidAmount:", bidAmount);
    //USDT_PER_BIDCOIN
    const usdtAmountInWei = parseUnits(bidAmount.toString(), 18);
    console.log("🚀 ~ burnBidHandler ~ usdtAmountInWei:", usdtAmountInWei);

    // Check allowance
    const currentAllowance = await bidCoinContract.methods
      .allowance(userWallet, contractAddress)
      .call();

    if (BigInt(currentAllowance) < BigInt(usdtAmountInWei)) {
      // Approve spending of USDT only if the current allowance is insufficient
      const approveTransactionParameters = {
        to: bidCoinAddress,
        from: userWallet,
        data: bidCoinContract.methods
          .approve(contractAddress, usdtAmountInWei)
          .encodeABI(),
      };
      console.log(
        "🚀 ~ burnBidHandler ~ approveTransactionParameters:",
        approveTransactionParameters
      );

      const approveTxHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [approveTransactionParameters],
      });
      console.log("🚀 ~ burnBidHandler ~ approveTxHash:", approveTxHash);

      // Wait for the approval transaction to be mined
      await new Promise((resolve) => {
        const checkTx = async () => {
          const receipt = await web3.eth.getTransactionReceipt(approveTxHash);
          if (receipt) {
            resolve();
          } else {
            setTimeout(checkTx, 1000); // Check again after 1 second
          }
        };
        checkTx();
      });
    } else {
      console.log("Sufficient allowance already exists. Skipping approval.");
    }

    // Place the bid
    const bidTransactionParameters = {
      to: contractAddress,
      from: userWallet,
      data: auctionContract.methods.placeBidWithBidCoin().encodeABI(),
    };

    const bidTxHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [bidTransactionParameters],
    });
    console.log("🚀 ~ burnBidHandler ~ bidTxHash:", bidTxHash);

    const totalProfit = await auctionContract.methods.totalProfit().call();
    const assetDescription = await auctionContract.methods
      .assetDescription()
      .call();
    const assetLocation = await auctionContract.methods.assetLocation().call();
    const assetType = await auctionContract.methods.assetType().call();
    const auctionEndTime = await auctionContract.methods
      .auctionEndTime()
      .call();
    const auctionEnded = await auctionContract.methods.auctionEnded().call();
    const currentBidPrice = await auctionContract.methods
      .currentBidPrice()
      .call();
    const fomoPhaseActive = await auctionContract.methods
      .fomoPhaseActive()
      .call();
    const winningBidder = await auctionContract.methods.winningBidder().call();

    const addressArray = await window.ethereum.request({
      method: "eth_accounts",
    });

    const userAddress = addressArray.length > 0 ? addressArray[0] : "0x0";
    const usdtBalance = await tetherTokenContract.methods
      .balanceOf(userWallet)
      .call();
    const bidBalance = await bidCoinContract.methods
      .balanceOf(userWallet)
      .call();

    const safeFormatUnits = (value, decimals) => {
      try {
        return Number(formatUnits(value || "0", decimals)).toString();
      } catch (error) {
        console.error("Error formatting units:", error);
        return "0";
      }
    };

    let auctionData = {
      totalProfit,
      assetDescription,
      assetLocation,
      assetType,
      auctionEndTime,
      auctionEnded,
      currentBidPrice,
      discountFee,
      fomoPhaseActive,
      winningBidder,
      usdtBalance: safeFormatUnits(usdtBalance, 18),
      bidBalance: safeFormatUnits(bidBalance, 18),
      address: contractAddress,
    };

    // return auctionData;
    return {
      status: true,
      data: auctionData,
    };
  } catch (error) {
    console.error("Error burning bid: " + error);
    return {
      status: "😥 " + error.message,
    };
  }
};
