/**
 * import React from 'react';
import { useLoading } from './LoadingContext';

const SomeComponent = () => {
  const { setIsLoading } = useLoading();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Perform async operation
      await someAsyncOperation();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // ...rest of the component
};
 */
import React, { createContext, useState, useContext } from "react";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};
