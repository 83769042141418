import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import { useAuth } from "../Services/AuthContext";

const PasswordInput = ({ id, name, value, onChange, placeholder }) => {
  const [showPassword, setShowPassword] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return (
    <div className="relative">
      <input
        id={id}
        name={name}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        required
        className="w-full px-3 py-2 pr-10 rounded-md  bg-gray-700 text-white border border-gray-800"
        placeholder={placeholder}
      />
      <button
        type="button"
        className="absolute inset-y-0 right-0 pr-3 flex items-center"
        onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
      </button>
    </div>
  );
};

const ConfirmPasswordInput = ({ id, name, value, onChange, placeholder }) => {
  const [peekPassword, setPeekPassword] = useState(false);

  return (
    <div className="relative">
      <input
        id={id}
        name={name}
        type="password"
        value={value}
        onChange={onChange}
        required
        className="w-full px-3 py-2 pr-10  bg-gray-700 text-white border border-gray-800 rounded-md "
        placeholder={placeholder}
      />
      <button
        type="button"
        className="absolute inset-y-0 right-0 pr-3 flex items-center"
        onMouseDown={() => setPeekPassword(true)}
        onMouseUp={() => setPeekPassword(false)}
        onMouseLeave={() => setPeekPassword(false)}>
        <Eye size={20} />
      </button>
      {peekPassword && (
        <div className="absolute mt-1 p-2 bg-gray-700 text-white border border-gray-800 rounded shadow">
          {value}
        </div>
      )}
    </div>
  );
};

const FormContainer = ({ children, title, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className="bg-gray-800 rounded-lg p-6 sm:p-8 w-full max-w-md relative shadow-lg">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl font-bold"
          style={{ color: "var(--secondary-text)" }}>
          X
        </button>
        <h2
          className="text-2xl font-bold mb-4"
          style={{ color: "var(--primary-text)" }}>
          {title}
        </h2>
        {children}
      </div>
    </div>
  );
};

// List of countries
const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "CÃ´te d'Ivoire",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const RegistrationForm = ({ isOpen, onClose }) => {
  const { register, lastRefCode, error, logout } = useAuth(); // Use register from context
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    country: "",
    address: "",
    phoneNumber: "",
    refCode: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNextStep = () => setStep(2);
  const handlePrevStep = () => setStep(1);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Registration form submitted:", formData);
    const result = await register(formData);
    if (result.success) {
      console.log("Success Registering user:", result.data);
      onClose();
    } else {
      console.error("Error Registering user:", result.error);
    }
  };

  if (!isOpen) return null;
  return (
    <FormContainer
      title={step === 1 ? "Create an Account" : "Complete Your Profile"}
      onClose={onClose}
      className="bg-gray-800  rounded-lg p-8 max-w-md w-full relative">
      <form onSubmit={handleSubmit} className="space-y-4">
        {step === 1 ? (
          <>
            <div>
              <label htmlFor="username" className="block mb-1 text-gray-400">
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                value={formData.username}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-800 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-1 text-gray-400">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-800 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="password" className="block mb-1 text-gray-400">
                Password
              </label>
              <PasswordInput
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Enter your password"
              />
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="block mb-1 text-gray-400">
                Confirm Password
              </label>
              <ConfirmPasswordInput
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder="Confirm your password"
              />
            </div>
            <div>
              <label htmlFor="refCode" className="block mb-1 text-gray-400">
                *Referral Code --optional
              </label>
              <input
                id="refCode"
                name="refCode"
                type="text"
                value={lastRefCode != null ? lastRefCode : formData.refCode}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-800 rounded-md"
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <label htmlFor="firstName" className="block mb-1 text-gray-400">
                First Name
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                value={formData.firstName}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border bg-gray-700 text-white border-gray-800 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block mb-1 text-gray-400">
                Last Name
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                value={formData.lastName}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border bg-gray-700 text-white border-gray-800 rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="dateOfBirth"
                className=" block mb-1 text-gray-400">
                Date of Birth
              </label>
              <input
                id="dateOfBirth"
                name="dateOfBirth"
                type="date"
                value={formData.dateOfBirth}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border bg-gray-700 text-white border-gray-800 rounded-md"
              />
            </div>

            <div>
              <label htmlFor="country" className=" block mb-1 text-gray-400">
                Country
              </label>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border bg-gray-700 text-white border-gray-800 rounded-md">
                <option value="">Select a country</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="address" className="block mb-1 text-gray-400">
                Address
              </label>
              <input
                id="address"
                name="address"
                type="text"
                value={formData.address}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-800 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block mb-1 text-gray-400">
                Phone Number
              </label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="tel"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-800 rounded-md"
              />
            </div>
          </>
        )}
        <div className="flex justify-between mt-6">
          {step === 1 ? (
            <button
              type="button"
              onClick={handleNextStep}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
              Next
            </button>
          ) : (
            <>
              <button
                type="button"
                onClick={handlePrevStep}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400">
                Back
              </button>
              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
                Register
              </button>
            </>
          )}
        </div>
      </form>
    </FormContainer>
  );
};

export default RegistrationForm;
