import { toast } from "react-toastify";
import instance from "./AxiosConfig";

let intervalId = null;

const fetchAchievements = async () => {
  try {
    const response = await instance.get("/api/achievements");
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching achievements:", error);
    return [];
  }
};

export const initAchievementListener = () => {
  if (intervalId) {
    clearInterval(intervalId);
  }

  intervalId = setInterval(async () => {
    const achievements = await fetchAchievements();
    // console.log("🚀 ~ initAchievementListener ~ Is firing:" + Date.now());
    const storedAchievements =
      JSON.parse(localStorage.getItem("userAchievements")) || [];
    const eventCounts = JSON.parse(localStorage.getItem("eventCounts") || "{}");

    const updatedAchievements = achievements.map((achievement) => {
      const storedAchievement = storedAchievements.find(
        (a) => a.id === achievement.id
      ) || { ...achievement, isUnlocked: false, progress: 0 };
      if (!storedAchievement.isUnlocked) {
        let isUnlocked = true;
        let progress = 0;
        achievement.requirements.forEach((requirement) => {
          const eventCount = eventCounts[requirement.description] || 0;
          progress = Math.max(
            progress,
            Math.min(eventCount, requirement.value)
          );
          isUnlocked = isUnlocked && eventCount >= requirement.value;
        });
        if (isUnlocked && !storedAchievement.isUnlocked) {
          console.log(
            "initAchievementListener_Achievement Unlocked: " + achievement.name
          );
          toast.success(`Achievement Unlocked: ${achievement.name}!`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        storedAchievement.isUnlocked = isUnlocked;
        storedAchievement.progress = progress;
      }
      return storedAchievement;
    });

    localStorage.setItem(
      "userAchievements",
      JSON.stringify(updatedAchievements)
    );
  }, 30000); // Check every 30 seconds
};

export const stopAchievementListener = () => {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
};
export const refreshAchievements = async () => {
  console.log("Refreshing achievements");
  const achievements = await fetchAchievements();
  const storedAchievements =
    JSON.parse(localStorage.getItem("userAchievements")) || [];
  const eventCounts = JSON.parse(localStorage.getItem("eventCounts") || "{}");

  const updatedAchievements = achievements.map((achievement) => {
    const storedAchievement = storedAchievements.find(
      (a) => a.id === achievement.id
    ) || { ...achievement, isUnlocked: false, progress: 0 };

    if (!storedAchievement.isUnlocked) {
      let isUnlocked = true;
      let progress = 0;
      achievement.requirements.forEach((requirement) => {
        const eventCount = eventCounts[requirement.description] || 0;
        progress = Math.max(progress, Math.min(eventCount, requirement.value));
        isUnlocked = isUnlocked && eventCount >= requirement.value;
      });

      if (isUnlocked && !storedAchievement.isUnlocked) {
        console.log(
          "refreshAchievements_Achievement Unlocked: " + achievement.name
        );
        toast.success(`Achievement Unlocked: ${achievement.name}!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      storedAchievement.isUnlocked = isUnlocked;
      storedAchievement.progress = progress;
    }

    return storedAchievement;
  });

  localStorage.setItem("userAchievements", JSON.stringify(updatedAchievements));
};
