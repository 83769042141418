import React, { useEffect } from "react";
import CustomAppRouter from "./CustomAppRouter";
import { LoadingProvider } from "./LoadingContext";
import { UserDataProvider } from "../Services/UserDataContext";
import { StakingProvider } from "../Services/StakingContext";
import { AuthProvider } from "../Services/AuthContext";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { createAppKit } from "@reown/appkit/react";
import { onCLS, onFID, onLCP } from "web-vitals";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://3d94b76063ff4e2a1a0e0c713639bb7e@o4505849017270272.ingest.us.sentry.io/4508080612048896",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
LogRocket.init("eubsbl/bidcoin-beta");
onCLS(console.log);
onFID(console.log);
onLCP(console.log);
// 1. Get projectId
const projectId = "ac30a604c31f7616409992a002dfacc3";

// 2. Create a function to fetch the Blockchain API RPC URL
function getBlockchainApiRpcUrl(chainId) {
  return `https://rpc.walletconnect.org/v1/?chainId=eip155:${chainId}&projectId=${projectId}`;
}

// 3. Set the chains
const chains = [
  {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: getBlockchainApiRpcUrl(1),
    chainNamespace: "eip155",
  },
  {
    chainId: 42161,
    name: "Arbitrum",
    currency: "ETH",
    explorerUrl: "https://arbiscan.io",
    rpcUrl: getBlockchainApiRpcUrl(42161),
    chainNamespace: "eip155",
  },
];

// 3. Create a metadata object - optional
const metadata = {
  name: "Bidcoin",
  description:
    "Decentralized Penny Auction, the most advanced bidding platform of Web3.",
  url: "https://bidcoin.live", // origin must match your domain & subdomain
  icons: ["https://bidcoin.live/favicon.ico"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: "...", // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});
export const web3ModalCreator = createWeb3Modal({
  ethersConfig,
  chains: chains,
  projectId,
  enableAnalytics: true,
});

// 5. Create a AppKit instance
export const modalNinja = createAppKit({
  adapters: web3ModalCreator.getWalletProvider(),
  networks: chains,
  projectId,
  features: {
    analytics: true, // Optional - defaults to your Cloud configuration
  },
});
const AppRouter = () => {
  return (
    <div className="App">
      <LoadingProvider>
        <AuthProvider>
          {/* <UserDataProvider> */}
          <StakingProvider>
            <CustomAppRouter />
          </StakingProvider>
          {/* </UserDataProvider> */}
        </AuthProvider>
      </LoadingProvider>
      //{" "}
    </div>
  );
};

export default AppRouter;
