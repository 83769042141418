import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";
import AppRouter from "./Components/AppRouter";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "./store/toastStyles.css";
// import { AuthProvider } from "./Services/AuthContext";
// import { LoadingProvider } from "./Services/LoadingContext";
// import store from "./app/store";
// import { Provider } from "react-redux";
const container = document.getElementById("root");
import ReactDOM from "react-dom";

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// // reportWebVitals();
