import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    // You can also log the error to an error reporting service here
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen bg-gray-400 flex items-center justify-center px-4">
          <div className="max-w-lg w-full bg-white shadow-lg rounded-lg p-8">
            <h1 className="text-3xl font-bold text-red-600 mb-4">
              Oops! Something went wrong.
            </h1>
            <p className="text-gray-600 mb-4">
              We're sorry, but an error occurred while rendering this component.
            </p>
            <details className="mb-4">
              <summary className="text-blue-600 cursor-pointer">
                View error details
              </summary>
              <pre className="mt-2 p-4 bg-gray-100 rounded overflow-auto text-sm">
                <code>{this.state.error && this.state.error.toString()}</code>
                <code>
                  {this.state.errorInfo && this.state.errorInfo.componentStack}
                </code>
              </pre>
            </details>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => window.location.reload()}>
              Reload Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
