import React, { useEffect, useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

const CookieConsentBanner = ({ onAccept }) => {
  useEffect(() => {
    const consent = getCookieConsentValue("yourCookieConsent");
    if (consent) {
      onAccept();
    }
  }, [onAccept]);
  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      cookieName="yourCookieConsent"
      onAccept={onAccept}
      // enableDeclineButton={true}
      // style={{ background: "#2B373B" ,align:center}}
      // buttonStyle={{ color: "#4e503b", fontSize: "16px" }}>
      style={{
        background: "#2B373B",
        textAlign: "center",
        display: "flex",
        // flexDirection: "column",
        alignItems: "center",
      }}
      buttonStyle={{ color: "#4e503b", fontSize: "16px", marginTop: "10px" }}>
      🍪 We use cookies.
      <br />
      <span style={{ fontSize: "16px" }}>
        By using our website, you consent to our use of cookies.
      </span>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
