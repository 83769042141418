import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useSound from "../Services/SoundService";
import {
  BadgeDollarSign,
  CircleAlert,
  CircleSlash,
  FileQuestion,
  Home,
  NotebookText,
  Vote,
} from "lucide-react";

const AppFooter = () => {
  const links = [
    { name: "Home", href: "/", icon: Home },
    { name: "How it works", href: "/HowItWorks", icon: FileQuestion },
    // { name: "Presale", href: "/Presale", icon: FileQuestion },
    { name: "Auctions", href: "/Auctions", icon: BadgeDollarSign },
    { name: "Staking", href: "/StakingInterface", icon: CircleSlash },
    // { name: "Referrals", href: "/ReferralUI", icon: CircleAlert },
    // { name: "Governance", href: "/GovernanceVoting", icon: Vote },
  ];
  const whitepaper = {
    name: "Whitepaper",
    href: "https://whitepaper.bidcoin.live",
    icon: NotebookText,
  };
  const navigate = useNavigate();
  const playNavigationSound = useSound("/sounds/tab_change.wav", 1); // Adjust the path and volume as needed

  const handleNavigation = (path) => {
    playNavigationSound();
    // Ensure scroll is completed before navigating
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  return (
    <footer className="bg-gray-800 mt-12 py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-bold mb-4">BidCoin</h3>
            <p className="text-gray-400">
              Revolutionizing decentralized auctions with blockchain technology.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Quick Links</h3>
            {links.map((link) => (
              <a
                key={link.href}
                onClick={() => handleNavigation(link.href)}
                className="flex items-center px-0 pb-2 text-gray-400 hover:text-white hover:cursor-pointer  rounded transition duration-150 ease-in-out">
                {link.name}
              </a>
            ))}
            <a
              key={whitepaper.href}
              href={whitepaper.href}
              className="flex items-center px-0 pb-2 text-gray-400 hover:text-white hover:cursor-pointer  rounded transition duration-150 ease-in-out">
              {whitepaper.name}
            </a>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Community</h3>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Discord
                </a>
              </li>
              <li>
                <a
                  href="https://x.com/BidCoinNews"
                  className="text-gray-400 hover:text-white">
                  X
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Telegram
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Medium
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="/TermAndConditions"
                  className="text-gray-400 hover:text-white">
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href="/legalrefund"
                  className="text-gray-400 hover:text-white">
                  Refund Policy
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Cookie Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p className="text-gray-400">
            &copy; 2024 BidCoin. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default AppFooter;
