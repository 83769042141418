import abi from "../assets/abis/AuctionPlatformABI.json";
import { getGlobalState, setGlobalState } from "../store/index";

import Web3 from "web3";
// All Contracts' ABI
import AuctionPlatformABI from "../assets/abis/v4/NewAuction.json";
import TetherToken from "../assets/abis/v4/CUSDT.json";
import BidCoin from "../assets/abis/v4/BidCoin.json";
import PresaleContract from "../assets/abis/v4/Presale.json";
import StakingContract from "../assets/abis/v4/StakingContract.json";
import GovernanceContract from "../assets/abis/v4/GovernanceContract.json";
import Treasury from "../assets/abis/v4/Treasury.json";
import AuctionFactory from "../assets/abis/v4/AuctionFactory.json";
import NewAuction from "../assets/abis/v4/NewAuction.json";
import { toast } from "react-toastify";
import { formatUnits } from "ethers";

const { ethereum } = window;
const ContractAbi = abi.abi;
let tx;

const getEthereumContract = async () => {
  const connectedAccount = getGlobalState("connectedAccount");

  if (connectedAccount) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(ContractAddress, ContractAbi, signer);

    return contract;
  } else {
    return getGlobalState("contract");
  }
};
const initAllContracts = async () => {
  try {
    const web3 = new Web3(Web3.givenProvider || process.env.REACT_APP_RPC_URL);
    setGlobalState("web3", web3);

    const networkId = await web3.eth.net.getId();
    if (networkId != 42161) {
      console.log(`Unexpected networkId: ${networkId}. Expected 1337.`);
      // return;
    }

    const deployedContracts = {
      tetherToken: new web3.eth.Contract(
        TetherToken.abi,
        process.env.REACT_APP_TETHERTOKEN_ADDRESS
      ),
      // bidCoin: new web3.eth.Contract(
      //   BidCoin.abi,
      //   process.env.REACT_APP_BIDCOIN_ADDRESS
      // ),
      presale: new web3.eth.Contract(
        PresaleContract.abi,
        process.env.REACT_APP_PRESALE_ADDRESS
      ),
      governanceContract: new web3.eth.Contract(
        GovernanceContract.abi,
        process.env.REACT_APP_GOVERNANCECONTRACT_ADDRESS
      ),
      // treasury: new web3.eth.Contract(
      //   Treasury.abi,
      //   process.env.REACT_APP_TREASURY_ADDRESS
      // ),
      auctionFactory: new web3.eth.Contract(
        AuctionFactory.abi,
        process.env.REACT_APP_AUCTIONFACTORY_ADDRESS
      ),
    };

    setGlobalState("contracts", deployedContracts);
  } catch (error) {
    console.error("Error initializing web3:", error);
  }
};

const isWalletConnected = async () => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const accounts = await ethereum.request({ method: "eth_accounts" });
    setGlobalState("connectedAccount", accounts[0]?.toLowerCase());

    window.ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });

    window.ethereum.on("accountsChanged", async () => {
      setGlobalState("connectedAccount", accounts[0]?.toLowerCase());
      await isWalletConnected();
    });

    if (accounts.length) {
      setGlobalState("connectedAccount", accounts[0]?.toLowerCase());
      console.log("Wallet already connected, fetching user data.");
      await fetchUserData();
    } else {
      alert("Please connect wallet.");
      connectWallet();
      console.log("No accounts found.");
    }
  } catch (error) {
    toast.error(error);
  }
};

const connectWallet = async () => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    setGlobalState("connectedAccount", accounts[0]?.toLowerCase());
    await fetchUserData();
  } catch (error) {
    toast.error(error);
  }
};

const updatePrice = async ({ tokenId, price }) => {
  try {
    if (!ethereum) return alert("Please install Metamask");
    const connectedAccount = getGlobalState("connectedAccount");
    const contract = await getEthereumContract();
    tx = await contract.changePrice(tokenId, toWei(price), {
      from: connectedAccount,
    });
    await tx.wait();
    await loadAuctions();
  } catch (error) {
    toast.error(error);
  }
};

const loadAuctions = async (id) => {
  try {
    // if (!ethereum) return alert("Please install Metamask");
    const contracts = getGlobalState("contracts");
    if (!contracts) return;
    // Fetch auctions data
    if (contracts.auctionFactory && contracts.auctionFactory.methods) {
      const auctions = await contracts.auctionFactory.methods
        .getAllAuctions()
        .call();
      // console.log("Auctions :" + auctions);
      setGlobalState("auctionAddresses", auctions);
      // console.log("🚀 ~ loadAuctions ~ auctions:", auctions);
    }
    const auctionAddresses = getGlobalState("auctionAddresses");
    return auctionAddresses;

    // setGlobalState("auction", structuredAuctions([auction])[0]);
  } catch (error) {
    toast.error(error);
    console.error("Error loading auctions:", error);
  }
};
const fetchUserReferralData = async () => {
  // Add refLink to walletData in userObj in `me` [API]
  try {
    // if (!ethereum) return alert("Please install Metamask");
    // const connectedAccount = getGlobalState("connectedAccount");
    // await isWalletConnected();
    // if (connectedAccount != null) {
    //   // console.log(connectedAccount);
    //   const response = await instance.get(
    //     `/api/Affiliate/referral-stats/${connectedAccount}`
    //   );
    //   if (response.data === "User not found") {
    //     const register = await axios.post("/api/Affiliate/register-user", {
    //       walletAddress: connectedAccount, // 0x70997970C51812dc3A010C7d01b50e0d17dc79C8 <- 16 coins stakedHardCoded. reward 0
    //     });
    //     return;
    //   }
    //   setGlobalState("userReferralData", response.data.data);
    //   return response.data.data;
    // }
  } catch (error) {
    console.error(error);
  }
};

const updateUserWalletData = async (walletData) => {
  try {
    // console.log("Updating User wallet data in ===>");
    // console.log(walletData);
    setGlobalState("userReferralData", walletData);
    return walletData;
  } catch (error) {
    console.error("Error updating user wallet data:", error);
    // throw new Error("Failed to update wallet data");
  }
};

const fetchUserData = async () => {
  try {
    // console.log("fetching user data");

    // if (!ethereum) return alert("Please install Metamask");
    if (!window.ethereum) {
      return {
        status: "💡 Connect your wallet provider to continue.",
      };
    }

    const addressArray = await window.ethereum.request({
      method: "eth_accounts",
    });

    const userAddress = addressArray.length > 0 ? addressArray[0] : "0x0";
    console.log("🚀 ~ fetchUserData ~ userAddress:", userAddress);
    // const connectedAccount = getGlobalState("connectedAccount");
    const contracts = getGlobalState("contracts");

    // console.log("🚀 ~ fetchUserData ~ connectedAccount:", connectedAccount);
    // console.log("🚀 ~ fetchUserData ~ contracts:", contracts);
    if (userAddress && contracts) {
      let userData = {};
      userData.address = userAddress;
      // console.log("🚀 ~ fetchUserData ~ userData.address:", userData.address);

      // Fetch BidCoin balance
      if (contracts.bidCoin && contracts.bidCoin.methods) {
        const balance = await contracts.bidCoin.methods
          .balanceOf(userAddress)
          .call();
        userData.bidBalance = Number(formatUnits(balance, 18)).toFixed(2);
        // console.log(
        // "🚀 ~ fetchUserData ~  userData.bidBalance:",
        // userData.bidBalance
        // );
      }

      // Fetch USDT balance
      if (contracts.tetherToken && contracts.tetherToken.methods) {
        const usdtBalance = await contracts.tetherToken.methods
          .balanceOf(userAddress)
          .call();
        console.log("🚀 ~ fetchUserData ~ usdtBalance:", usdtBalance);
        userData.usdtBalance = Number(formatUnits(usdtBalance, 18)).toFixed(2);
      }

      // Fetch staking data
      if (contracts.stakingContract && contracts.stakingContract.methods) {
        const stakerInfo = await contracts.stakingContract.methods
          .stakers(userAddress)
          .call();
        const totalStakedContract = await contracts.stakingContract.methods
          .totalStaked()
          .call();

        userData.totalStakedContract = Number(
          formatUnits(totalStakedContract, 18)
        ).toFixed(2);
        userData.totalStaked = Number(
          formatUnits(stakerInfo.stakedAmount, 18)
        ).toFixed(2);
        userData.totalReward = Number(
          formatUnits(stakerInfo.rewardAmount, 18)
        ).toFixed(2);
      }

      // Fetch circulating supply
      if (contracts.bidCoin && contracts.bidCoin.methods) {
        const circulatingSupply = await contracts.bidCoin.methods
          .totalSupply()
          .call();
        userData.totalCirculatingSupply = Number(
          formatUnits(circulatingSupply, 18)
        ).toFixed(2);
      }

      // Fetch presale data
      if (contracts.presale && contracts.presale.methods) {
        const isActive = await contracts.presale.methods
          .isPresaleActive()
          .call();
        const balance = await contracts.presale.methods
          .presalePurchases(userAddress)
          .call();
        const burntAmount = await contracts.presale.methods
          .burntAmount(userAddress)
          .call();
        const initialStakingLimit = await contracts.presale.methods
          .INITIAL_STAKING_LIMIT()
          .call();
        const burnToStakeRatio = await contracts.presale.methods
          .BURN_TO_STAKE_RATIO()
          .call();
        const maxStakingLimit = await contracts.presale.methods
          .MAX_STAKING_LIMIT()
          .call();
        const maxTokensPerUser = await contracts.presale.methods
          .MAX_TOKENS_PER_USER()
          .call();
        const burnLimit = await contracts.presale.methods.BURN_LIMIT().call();
        const discount = await contracts.presale.methods.discount().call();
        const rate = await contracts.presale.methods.rate().call();

        userData.presaleData = {
          isActive: isActive,
          discount: Number(discount).toFixed(2),
          rate: Number(rate).toFixed(2),
          balance: Number(formatUnits(balance, 18)).toFixed(2),
          burntAmount: Number(formatUnits(burntAmount, 18)).toFixed(2),
          initialStakingLimit: Number(initialStakingLimit).toFixed(2),
          burnToStakeRatio: Number(burnToStakeRatio).toFixed(2),
          maxStakingLimit: Number(maxStakingLimit).toFixed(2),
          maxTokensPerUser: Number(formatUnits(maxTokensPerUser, 18)).toFixed(
            2
          ),
          burnLimit: Number(formatUnits(burnLimit, 18)).toFixed(2),
        };
      }

      setGlobalState("userData", userData);
      // console.log("User data fetched successfully.");
      // console.log(userData);
      return userData;
    }
  } catch (error) {
    // console.error("Connected account or contracts not available", error);
    console.error("Error fetching user data:", error);
    toast.error("Failed to fetch user data");
  }
};
export {
  isWalletConnected,
  connectWallet,
  initAllContracts,
  loadAuctions,
  fetchUserData,
  updatePrice,
  fetchUserReferralData,
  updateUserWalletData,
};
