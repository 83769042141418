import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import instance from "../Services/AxiosConfig";
// import LoadingScreen from "../Components/LoadingScreen";
import { updateUserWalletData } from "../Services/BlockChainService";
import { toast } from "react-toastify";
import "../store/toastStyles.css";
import { getCookieConsentValue } from "react-cookie-consent";

const AuthContext = createContext({
  user: null,
  lastRefCode: null,
  loading: true,
  error: null,
  login: async () => {},
  logout: async () => {},
  register: async () => {},
  setRefCode: async () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState({
    user: null,
    lastRefCode: null,
    loading: true,
    error: null,
  });
  const toastShownRef = useRef(false);

  const setRefCode = useCallback(async (code) => {
    try {
      localStorage.setItem("lastRefCode", code);
      setState((prevState) => ({ ...prevState, lastRefCode: code }));
    } catch (error) {
      console.error("Error saving ref code:", error);
      setState((prevState) => ({
        ...prevState,
        error: "Failed to save referral code",
      }));
    }
  }, []);

  const refreshToken = useCallback(async () => {
    try {
      const response = await instance.post("/api/Auth/refresh-token", {
        token: localStorage.getItem("token"),
        refreshToken: localStorage.getItem("refreshToken"),
      });
      const { token, refreshToken, user } = response.data.data;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      setState((prevState) => ({ ...prevState, user }));
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return token;
    } catch (error) {
      console.error("Failed to refresh token:", error);
      await logout();
      return null;
    }
  }, []);

  const updateWalletData = useCallback(async (walletData) => {
    try {
      await updateUserWalletData(walletData);
    } catch (error) {
      console.error("Error updating wallet data:", error);
      setState((prevState) => ({
        ...prevState,
        error: "Failed to update wallet data",
      }));
    }
  }, []);

  const initAuth = useCallback(async () => {
    const consent = getCookieConsentValue("yourCookieConsent");
    if (!consent) {
      await silentLogout();
      setState((prevState) => ({ ...prevState, loading: false }));
      return;
    }

    const token = localStorage.getItem("token");
    if (token) {
      try {
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await instance.get("/api/Auth/me");
        const user = response.data.data.user;
        setState((prevState) => ({ ...prevState, user, loading: false }));
        if (user.walletData) {
          await updateWalletData(user.walletData);
        }
        if (!toastShownRef.current) {
          toast("Welcome Back!");
          toastShownRef.current = true;
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        if (error.response && error.response.status === 401) {
          const newToken = await refreshToken();
          if (newToken) {
            try {
              const retryResponse = await instance.get("/api/Auth/me");
              const user = retryResponse.data.data.user;
              setState((prevState) => ({ ...prevState, user, loading: false }));
              if (user.walletData) {
                await updateWalletData(user.walletData);
              }
            } catch (retryError) {
              console.error(
                "Failed to fetch user data after token refresh:",
                retryError
              );
              await logout();
            }
          } else {
            await logout();
          }
        } else {
          await logout();
        }
      }
    } else {
      await logout();
    }
  }, [refreshToken, updateWalletData]);

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  const register = useCallback(async (formData) => {
    try {
      const response = await instance.post("/api/Auth/register", formData);
      const { token, refreshToken, user } = response.data.data;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      setState((prevState) => ({ ...prevState, user, error: null }));
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return { success: true, data: user };
    } catch (error) {
      console.error(
        "Error Registering user:",
        error.response?.data || error.message
      );
      setState((prevState) => ({ ...prevState, error: "Registration failed" }));
      return { success: false, error: error.response?.data || error.message };
    }
  }, []);

  const login = useCallback(async (username, password) => {
    try {
      const response = await instance.post("/api/Auth/login", {
        username,
        password,
      });
      const { token, refreshToken, user } = response.data.data;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      setState((prevState) => ({ ...prevState, user, error: null }));
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return { success: true };
    } catch (error) {
      console.error("Login failed:", error);
      let errorMessage = "Login failed. Please try again.";
      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = "Invalid credentials. Please check your input.";
            break;
          case 401:
            errorMessage = "Incorrect username or password.";
            break;
          case 404:
            errorMessage = "User not found.";
            break;
          case 429:
            errorMessage = "Too many login attempts. Please try again later.";
            break;
          default:
            errorMessage = "An error occurred. Please try again.";
        }
      }
      setState((prevState) => ({ ...prevState, error: errorMessage }));
      return { success: false, error: errorMessage };
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      await instance.post("/api/Auth/logout", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      document.cookie =
        "authCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        ".AspNetCore.Cookies=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      delete instance.defaults.headers.common["Authorization"];
      setState((prevState) => ({ ...prevState, user: null, error: null }));
      if (!toastShownRef.current) {
        toast("See you soon!");
        toastShownRef.current = true;
      }
    }
  }, []);

  const silentLogout = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      await instance.post("/api/Auth/logout", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.error("Silent logout failed:", error);
    } finally {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      document.cookie =
        "authCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        ".AspNetCore.Cookies=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      delete instance.defaults.headers.common["Authorization"];
      setState((prevState) => ({ ...prevState, user: null, error: null }));
    }
  }, []);

  // if (state.loading) {
  //   return <LoadingScreen />;
  // }

  const contextValue = {
    ...state,
    setRefCode,
    login,
    logout,
    register,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
